import { Link } from "gatsby";
import Img, { FixedObject } from "gatsby-image";
import React from "react";

interface HeaderProps {
  siteTitle: string;
  menuItems: [
    {
      url: string;
      label: string;
    }
  ];
  logo: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
}

const Header: React.FC<HeaderProps> = ({ siteTitle, menuItems, logo }) => (
  <header className="container mx-auto p-3 flex flex-initial flex-row flex-wrap items-center">
    <Link to="/" className="logo-link">
      <Img
        fixed={logo.childImageSharp.fixed}
        alt="Component Based Carlos site logo"
        style={{ marginBottom: 0 }}
      />
      <span className="sr-only">{siteTitle}</span>
    </Link>
    <nav className="sm:ml-auto">
      {menuItems.length && (
        <ul role="list" className="flex flex-initial flex-row space-x-8">
          {menuItems.map((menuItem, index) => (
            <li key={index}>
              <Link
                to={menuItem.url}
                className="text-2xl font-normal text-darkgray hover:text-scarlet"
              >
                {menuItem.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </nav>
  </header>
);

export default Header;
