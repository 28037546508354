import axios from "axios";
import React from "react";
import { useMutation } from "react-query";

const submitNewsetter = async ({
  name,
  email,
}: {
  name: string;
  email: string;
}) => {
  const { data } = await axios({
    url: "https://wp.componentbasedcarlos.com/graphql",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "post",
    data: {
      query: `
        mutation NewsletterMutation {
          __typename
          createNewsletterEntry(input: {clientMutationId: "Example", email: "${email}", name: "${name}"}) {
            data
            success
          }
        }
      `,
    },
  });
  return data;
};

const Newsletter = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mutate, { status, data, error }] = useMutation(submitNewsetter);

  const onSubmitNewsletter = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await mutate({ name, email });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmailChange = React.useCallback(e => {
    setEmail(e.target.value);
  }, []);

  const handleNameChange = React.useCallback(e => {
    setName(e.target.value);
  }, []);

  return (
    <div className="p-8 bg-gray rounded-md shadow-lg lg:-mt-16">
      {status !== "success" && (
        <form onSubmit={onSubmitNewsletter} className="flex flex-col">
          <div className="flex flex-col">
            <h2 className="text-2xl text-scarlet pb-2">Join My Newsletter</h2>
            <label htmlFor="newsletter-name" className="pb-1 text-scarlet">
              Name
            </label>
            <input
              className="py-2 px-3 border border-solid border-scarlet rounded-md"
              id="newsletter-name"
              name="newsletter-name"
              value={name}
              onChange={handleNameChange}
              type="text"
              required
            />
          </div>
          <div className="flex flex-col pt-4">
            <label htmlFor="newsletter-email" className="pb-1 text-scarlet">
              Email
            </label>
            <input
              className="py-2 px-3 border border-solid border-scarlet rounded-md"
              id="newsletter-email"
              name="newsletter-email"
              value={email}
              onChange={handleEmailChange}
              type="email"
              required
            />
          </div>
          <button
            type="submit"
            disabled={status === "loading"}
            className="btn btn-red mt-8 disabled:opacity-50"
          >
            {status === "loading" ? "Loading" : "Subscribe"}
          </button>
        </form>
      )}
      {status === "success" && (
        <p className="text-xl text-scarlet flex flex-col items-center justify-center h-full">
          Thank you for signing up!
        </p>
      )}
    </div>
  );
};

export default Newsletter;
