import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import "../css/tailwind-components.css";
import Footer from "./footer";
import Header from "./header";

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wordPress {
        menu(id: "bmF2X21lbnU6Mg==") {
          menuItems {
            nodes {
              url
              label
            }
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const menuItems = data.wordPress.menu?.menuItems?.nodes || [];

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        menuItems={menuItems}
        logo={data.logo}
      />
      <div {...props}>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
