/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

interface SeoProps {
  description?: string;
  lang?: string;
  meta?: [];
  title: string;
  canonical?: string;
}

const SEO: React.FC<SeoProps> = ({
  description = "",
  lang = "en",
  meta = [],
  title,
  canonical = "",
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            defaultImage: image
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const image = `${site.siteMetadata.url}${site.siteMetadata.defaultImage}`;

  console.log(image);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: image,
        },
      ].concat(meta)}
      link={
        canonical ? [{ rel: "canonical", key: canonical, href: canonical }] : []
      }
    />
  );
};

export default SEO;
