import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Newsletter from "./sections/newsletter";

const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query FooterLogoQuery {
      m2FullStack: file(relativePath: { eq: "m2-full-stack-developer.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      m2Professional: file(
        relativePath: { eq: "m2-professional-developer.png" }
      ) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      m2Frontend: file(
        relativePath: { eq: "m2-professional-frontend-developer.png" }
      ) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      m2Javascript: file(
        relativePath: { eq: "m2-professional-javascript-developer.png" }
      ) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      m2Associate: file(relativePath: { eq: "m2-associate-developer.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      carlos: file(relativePath: { eq: "carlos.jpg" }) {
        childImageSharp {
          fixed(width: 150, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);
  const {
    m2FullStack,
    m2Professional,
    m2Frontend,
    m2Javascript,
    m2Associate,
    carlos,
  } = data;

  return (
    <footer className="">
      <div className="w-11/12 md:w-1/2 mx-auto my-12 grid lg:grid-cols-3 md:grid-cols-1 grid-flow-row gap-30 py-8 px-4 shadow-lg rounded-md background-radial">
        <div className="col-span-1">
          <Img
            fixed={carlos.childImageSharp.fixed}
            className="rounded-full border-2 border-solid border-scarlet shadow-lg"
            alt="Picture of Carlos Cerda, owner of Component Based Carlos, posing with his eyes closed and hand to chin."
          />
        </div>
        <div className="col-span-2">
          <h2 className="text-white">Hi, I'm Carlos Cerda</h2>
          <p className="text-lg text-white pb-6">
            Im the <i>Carlos</i> in Component Based Carlos. Coding is my passion
            and components are my jam. I'm a certified full stack Magento
            developer by day and ReactJS + WordPress enthusiast by night.
          </p>
          <Link to="/contact" className="btn btn-white">
            Contact Me
          </Link>
        </div>
      </div>
      <div className="bg-blood mt-16">
        <div className="container mx-auto px-3 py-6 grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 grid-flow-row gap-10">
          <div className="text-gray">
            <div>© {new Date().getFullYear()} Component Based Carlos</div>
            <div className="pt-1">Built with GatsbyJS and WordPress</div>
            <ul className="flex flex-row space-x-3 pt-2">
              <li className="flex">
                <a
                  className="icon-twitter flex justify-center items-center text-xl text-white"
                  href="https://twitter.com/ccerda0520"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="sr-only">
                    Link to Carlos Cerda Twitter profile
                  </span>
                </a>
              </li>
              <li className="flex">
                <a
                  className="icon-github flex justify-center items-center text-xl text-white"
                  href="https://github.com/ccerda0520"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="sr-only">
                    Link to Carlos Cerda Github profile
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <Newsletter />
          <div>
            <div className="text-center pb-3">
              <a
                href="https://magento-u.magento.com/magento-u/Full_Stack_Developers.html"
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-center"
              >
                <Img
                  fixed={m2FullStack.childImageSharp.fixed}
                  alt="Magento 2 Certified Full Stack Developer logo"
                  style={{ marginBottom: 0 }}
                />
                <span className="sr-only">
                  Link to the Magento full stack developer directory
                </span>
              </a>
            </div>
            <ul className="flex flex-initial flex-row space-x-4 ml-auto justify-center">
              <li>
                <a
                  href="https://u.magento.com/certification/directory/dev/3219109/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex"
                >
                  <Img
                    fixed={m2Professional.childImageSharp.fixed}
                    alt="Magento 2 Certified Professional Developer logo"
                    style={{ marginBottom: 0 }}
                  />
                  <span className="sr-only">
                    Link to my Magento certification directory profile
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://u.magento.com/certification/directory/dev/3219109/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex"
                >
                  <Img
                    fixed={m2Frontend.childImageSharp.fixed}
                    alt="Magento 2 Certified Frontend Developer logo"
                    style={{ marginBottom: 0 }}
                  />
                  <span className="sr-only">
                    Link to my Magento certification directory profile
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://u.magento.com/certification/directory/dev/3219109/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex"
                >
                  <Img
                    fixed={m2Javascript.childImageSharp.fixed}
                    alt="Magento 2 Certified Javascript Developer logo"
                    style={{ marginBottom: 0 }}
                  />
                  <span className="sr-only">
                    Link to my Magento certification directory profile
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://u.magento.com/certification/directory/dev/3219109/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex"
                >
                  <Img
                    fixed={m2Associate.childImageSharp.fixed}
                    alt="Magento 2 Certified Associate Developer logo"
                    style={{ marginBottom: 0 }}
                  />
                  <span className="sr-only">
                    Link to my Magento certification directory profile
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
